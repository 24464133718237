import Layout from '../common/layout';
import React, { Component } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import privacy_policy from './privacy_content';

class PrivacyPolicy extends Component {
    render() {
        return (
            <Layout>
                <Container maxWidth="m">
                    <Box
                        sx={{
                            bgcolor: '#cfe8fc',
                            color: 'black',
                            fontFamily: 'Roboto',
                            padding: '5px',
                            fontSize: 'small'
                        }}>
                        <span
                            dangerouslySetInnerHTML={{ __html: privacy_policy }}
                        />
                    </Box>
                </Container>
            </Layout>
        );
    }
}

export default PrivacyPolicy;
