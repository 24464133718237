import React, { Component } from 'react';
import { useState } from 'react';
import './layout.css';
import TopNav from './topnav';

const Layout = (props) => {
    return (
        <div className="main-container">
            <TopNav />
            <div>{props.children}</div>
        </div>
    );
};
export default Layout;
