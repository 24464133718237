import { AppBar } from '@mui/material';
import React, { Component } from 'react';
import { useState } from 'react';
import './layout.css';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuItem, Menu } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

const TopNav = (props) => {
    const [authenticated, setAuthenticated] = useState(
        props.authenticated || false
    );
    const [userMenuAnchorElement, setUserMenuAnchorElement] = useState(null);

    const handleUserMenuOpened = (event) => {
        setUserMenuAnchorElement(event.currentTarget);
    };

    const handleUserMenuClosed = (event) => {
        setUserMenuAnchorElement(null);
    };

    const handleProfileMenuItemSelected = (event) => {
        console.log(event);
    };

    const handleAccountMenuItemSelected = (event) => {
        console.log(event);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}>
                        M-Available
                    </Typography>
                    {authenticated && (
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleUserMenuOpened}
                                color="inherit">
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={userMenuAnchorElement}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                open={Boolean(userMenuAnchorElement)}
                                onClose={handleUserMenuClosed}>
                                <MenuItem
                                    onClick={handleProfileMenuItemSelected}>
                                    Profile
                                </MenuItem>
                                <MenuItem
                                    onClick={handleAccountMenuItemSelected}>
                                    My account
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                    {!authenticated && <Button color="inherit">Login</Button>}
                </Toolbar>
            </AppBar>
        </Box>
    );
};
export default TopNav;
