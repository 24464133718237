import '../common/layout.css';

const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <span style={{ textAlign: 'center' }}>
                <h1>M-Available</h1>
                <br />
                <br />
                <br />
                <p textAlign="center" style={{ fontSize: 'small' }}>
                    Coming Soon!
                </p>
            </span>
        </div>
    );
};

export default ComingSoon;
