import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MAvailableRootPage from './m-available';
import ComingSoon from './m-available/comingsoon';
import PrivacyPolicy from './documents/Privacy';

const reload = () => window.location.reload();
const MAvailableRoutes = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<ComingSoon />} />
            <Route exact path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
    </BrowserRouter>
);
export default MAvailableRoutes;
