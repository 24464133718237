import '../App.css';
import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import axios from 'axios';
import Layout from '../common/layout';

class MAvailableRootPage extends Component {
    async getProfile(userId) {
        await axios
            .get(`http://localhost:8080/api/Profiles?fbId=${userId}`)
            .then((res) => {
                const profile = res.data;
                if (!profile || (profile && !profile.length)) {
                    console.log('No profile');
                } else {
                    console.log('Profile found');
                    console.log(profile);
                }
            });
    }

    responseFacebook = (response) => {
        const FB = window.FB;
        console.log(response);
        const accessToken = response.accessToken;
        const userId = response.id;
        this.getProfile(userId);
    };

    sendRequest = async () => {
        await axios
            .get('http://localhost:8080/api/Profiles/utils/longTask')
            .then((res) => {
                console.log(res.data);
            });
    };

    render() {
        return (
            <Layout>
                <div>
                    <FacebookLogin
                        appId="2830828563898496"
                        autoLoad={true}
                        fields="name,email,picture,groups"
                        render={(renderProps) => (
                            <img
                                src="media/images/logos/login-with-facebook-button.png"
                                onClick={renderProps.onClick}
                            />
                        )}
                        callback={this.responseFacebook}
                    />
                </div>
            </Layout>
        );
    }
}
export default MAvailableRootPage;
